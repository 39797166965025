import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from '@mui/material';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { storageKey } from 'store/constant';
import { TextFieldStyle1, TextFieldStyle2 } from 'style/textField';
import { getPegawaibyNIP } from 'services/api/tbl';
import { ButtonShadow } from 'style/button';
import { IconEye, IconEyeOff } from '@tabler/icons';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';

import useScriptRef from 'hooks/useScriptRef';
import * as Yup from 'yup';
import AnimateButton from 'ui-component/extended/AnimateButton';
import PropTypes from 'prop-types';
import LupaKataSandi from '../../../../components/card/LupaKataSandi';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import Loader from 'ui-component/Loader';

// ============================|| FIREBASE - LOGIN ||============================ //

// eslint-disable-next-line react/prop-types
const FirebaseLogin = ({ open3, setOpen, setOpen2, setOpen3, handleClose3, isUnderMaintenance, ...others }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const scriptedRef = useScriptRef();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const emailValidation = (value) => {
        if (!value && !open3) {
            return 'Email diperlukan';
        }
        if (value.endsWith('@gmail.com')) {
            return true;
        }
        if (value.endsWith('@')) {
            return 'Masukan alamat domain';
        }
        if (value.includes('@') && !value.endsWith('@kemlu.go.id')) {
            return 'Lengkapi alamat domain (@kemlu.go.id)';
        }
        if (!value.includes('@')) {
            return true;
        }
        try {
            Yup.string().email('Harus alamat email yang valid').validateSync(value);
            return true;
        } catch (error) {
            return 'Harus alamat email yang valid';
        }
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .test('email', 'Harus alamat email yang valid', (value) => {
                const errorMessage = emailValidation(value);
                return errorMessage === true ? true : new Yup.ValidationError(errorMessage, value, 'email');
            })
            .max(255)
            .required('Email diperlukan'),
        password: Yup.string().max(255).required('Kata sandi diperlukan')
    });

    const fetchPegawai = async (nip) => {
        setLoading(true);
        await getPegawaibyNIP({ nip })
            .then((res) => {
                if (res && res.success) {
                    // const nik = res?.data?.no_ktp ?? '';
                    const noKK = res?.data?.no_kk ?? '';
                    const gender = res?.data?.kelamin?.toLowerCase().includes('l') ? 'LAKI-LAKI' : 'PEREMPUAN';
                    const masaKerja = res?.data?.masa_kerja ?? null;
                    const isCLTN = res?.data?.jabatan?.toLowerCase().endsWith('tanggungan negara');
                    // localStorage.setItem(storageKey.USER_NIK, nik);
                    localStorage.setItem(storageKey.USER_NO_KK, noKK);
                    localStorage.setItem(storageKey.USER_GENDER, gender);
                    localStorage.setItem(storageKey.USER_WORK_TIME, masaKerja);
                    localStorage.setItem(storageKey.USER_CLTN, JSON.stringify(isCLTN));
                } else {
                    console.error('An error occurred:', res.message);
                    // dispatch({
                    //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    //     type: SNACKBAR_OPEN,
                    //     open: true,
                    //     message: res.message,
                    //     alertSeverity: 'warning',
                    //     variant: 'alert'
                    // });
                }
            })
            .catch((e) => {
                console.error(e);
                // dispatch({
                //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
                //     type: SNACKBAR_OPEN,
                //     open: true,
                //     message: e?.response?.data?.message,
                //     alertSeverity: 'warning',
                //     variant: 'alert'
                // });
            })
            .finally(() => {
                setLoading(false);
                return null;
            });
    };

    return (
        <>
            {loading && <Loader />}
            {open3 === 'lupaKataSandi' && <LupaKataSandi open={open3 === 'lupaKataSandi'} handleClose={handleClose3} />}

            <Grid item xs={12} container alignItems="center" justifyContent="center">
                <Typography variant="subtitle1" color="rgba(64, 64, 65, 1)">
                    Masuk
                </Typography>
            </Grid>

            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        setLoading(true);
                        if (!navigator.onLine) {
                            setErrors({ submit: 'Tidak ada koneksi internet. Periksa koneksi Anda dan coba lagi.' });
                            setSubmitting(false);
                            setOpen2(true);
                            return;
                        }
                        if (isUnderMaintenance) {
                            setErrors({ submit: 'Sistem saat ini sedang dalam perbaikan. Silahkan coba lagi nanti.' });
                            setSubmitting(false);
                            setOpen(true);
                            return;
                        }
                        const apiUrl = `${process.env.REACT_APP_API_URL}/auth/login`;
                        const payload = { identifier: values.email, password: values.password };
                        await axios
                            .post(apiUrl, payload, {
                                headers: {
                                    'Access-Control-Allow-Origin': '*',
                                    'Content-Type': 'application/json'
                                }
                            })
                            .then(async (res) => {
                                if (res && res.data) {
                                    const userInfo = jwtDecode(res?.data?.data?.token_access ?? res?.data?.data?.token_access);
                                    const token = res?.data?.data?.token_access ?? res?.data?.data?.token_access;
                                    localStorage.setItem(storageKey.USER_TOKEN, token);
                                    localStorage.setItem(storageKey.IS_LOGGED_ID, true);
                                    if (userInfo) {
                                        const userId = res?.data?.data?.id ?? null;
                                        const userNip = res?.data?.data?.nip ?? res?.data?.data?.auth_data?.NIP;
                                        const userName = res?.data?.data?.nama ?? res?.data?.data?.auth_data?.Nama;
                                        const userNIK = res?.data?.data?.nik ?? res?.data?.data?.nik ?? '';
                                        localStorage.setItem(storageKey.USER_ID, userId);
                                        localStorage.setItem(storageKey.USER_NIP, userNip);
                                        localStorage.setItem(storageKey.USER_NAME, userName);
                                        localStorage.setItem(storageKey.USER_INFO, JSON.stringify(userInfo));
                                        localStorage.setItem(storageKey.USER_NIK, userNIK);
                                        const userStatus = res?.data?.data?.status_pegawai ?? null;
                                        const userRole = res?.data?.data?.role.name ?? res?.data?.data?.role;
                                        const unker = res?.data?.data?.unit_kerja ?? res?.data?.data?.unit_kerja;
                                        localStorage.setItem(storageKey.USER_ROLE_NAME, userRole);
                                        localStorage.setItem(storageKey.USER_UNKER, unker);
                                        if (userStatus !== null) {
                                            localStorage.setItem(storageKey.USER_STATUS, userStatus);
                                        } else {
                                            localStorage.removeItem(storageKey.USER_STATUS);
                                        }
                                        fetchPegawai(userNip);
                                    }
                                    setTimeout(() => {
                                        const userRoleName = localStorage.getItem(storageKey.USER_ROLE_NAME);

                                        switch (userRoleName) {
                                            case 'Admin Satker':
                                            case 'Pegawai':
                                                navigate('/pengesahan-cacah-jiwa/kelahiran', { replace: true });
                                                break;
                                            case 'Admin PDTU':
                                            case 'Kabag PDTU':
                                            case 'Super Admin':
                                            case 'Kepala Biro SDM':
                                                navigate('/pengesahan-kenaikan-gaji-berkala', { replace: true });
                                                break;
                                            case 'Kasubag PDTU':
                                                navigate('/pengesahan-cuti/cltn', { replace: true });
                                                break;
                                            case 'Sekretaris Jenderal':
                                                navigate('/pengesahan-izin-menikah', { replace: true });
                                                break;
                                            default:
                                                break;
                                        }
                                    }, [1000]);
                                }
                                // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
                                // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
                                // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
                                // github issue: https://github.com/formium/formik/issues/2430
                            })
                            .catch((err) => {
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err?.response?.data?.message });
                                    setSubmitting(false);
                                }
                            });
                    } catch (err) {
                        console.error(err);
                        dispatch({
                            anchorOrigin: { vertical: 'top', horizontal: 'center' },
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: err.response?.data?.message,
                            alertSeverity: 'warning',
                            variant: 'alert'
                        });
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err?.response?.message });
                            setSubmitting(false);
                        }
                    } finally {
                        setLoading(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Email / Username</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-login"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                                disabled={isSubmitting}
                                sx={TextFieldStyle1}
                            />
                            {/* <FormHelperText id="standard-weight-helper-text-email-login">
                                <Typography variant="caption" color="gray">
                                    Dapat ditulis tanpa domain (@kemlu.go.id)
                                </Typography>
                            </FormHelperText> */}
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Kata Sandi</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="small"
                                        >
                                            {showPassword ? <IconEye /> : <IconEyeOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{}}
                                disabled={isSubmitting}
                                sx={TextFieldStyle2}
                            />
                            {touched.password && errors.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors.password}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Typography
                            variant="subtitle1"
                            onClick={() => setOpen3('lupaKataSandi')}
                            color="secondary"
                            sx={{ cursor: 'pointer', textAlign: 'left' }}
                        >
                            Lupa kata sandi?
                        </Typography>
                        {errors.submit && (
                            <Box>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 1 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting || values.email === '' || values.password === ''}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    sx={{ ...ButtonShadow(), borderRadius: '12px' }}
                                >
                                    Masuk
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

FirebaseLogin.propTypes = {
    loginProp: PropTypes.number
};

export default FirebaseLogin;

// switch (err.code) {
//     case 'auth/invalid-email':
//         setErrors({ submit: 'Invalid email address. Please enter a valid email.' });
//         break;
//     case 'auth/user-disabled':
//         setErrors({ submit: 'Your account has been disabled. Please contact support.' });
//         break;
//     case 'auth/user-not-found':
//         setErrors({ submit: 'No user found with the provided email address. Please sign up.' });
//         break;
//     case 'auth/wrong-password':
//         setErrors({ submit: 'Invalid password. Please check your password and try again.' });
//         break;
//     default:
//         if (err.message.toLowerCase().includes('network')) {
//             setErrors({
//                 submit: 'Network error. Please check your internet connection and try again.'
//             });
//         } else {
//             setErrors({ submit: 'An error occurred during sign-in. Please try again.' });
//         }
// }
